<template>
    <div>
        <!-- 说明页面 -->
        <transition name="guide-hide">
            <div class="guide" v-if="showGuide">
                <ul class="guide-title">
                    <li class="guide-title-info">
                        <h1>续周期</h1>
                        <p>延长使用时间，流量不增加</p>
                    </li>
                    <li class="guide-title-handle">
                        <button @click="handleOrder">立即续费<i class="iconfont iconarrow_right_fat"></i></button>
                    </li>
                </ul>
                <div class="guide-main">
                    <ul>
                        <li class="guide-main-contrast">
                            <h2>* 续周期前，联通的26号结算</h2>
                            <ul class="guide-main-contrast-list">
                                <li>
                                    <span class="emp">可使用周期</span>
                                    0
                                </li>
                                <li>
                                    <span>计费周期已用量</span>
                                    6.7978
                                </li>
                                <li>
                                    <span>计费期间可用量</span>
                                    1024
                                </li>
                                <li>
                                    <span>客户套餐</span>
                                    移动1G/月，移动3G/月，移动10G/年
                                </li>
                                <li>
                                    <span>初次使用时间</span>
                                    2019-06-01 00:00:00
                                </li>
                                <li>
                                    <span class="emp">到期时间</span>
                                    2019-05-31 00:00:00
                                </li>
                                <li>
                                    <span>周期开始时间</span>
                                    2019-06-01 00:00:00
                                </li>
                            </ul>
                        </li>
                        <li class="guide-main-contrast">
                            <h2>* 续周期后，联通的26号结算</h2>
                            <ul class="guide-main-contrast-list">
                                <li>
                                    <span class="emp">可使用周期</span>
                                    12
                                </li>
                                <li>
                                    <span>计费周期已用量</span>
                                    6.7978
                                </li>
                                <li>
                                    <span>计费期间可用量</span>
                                    1024
                                </li>
                                <li>
                                    <span>客户套餐</span>
                                    移动1G/月
                                </li>
                                <li>
                                    <span>初次使用时间</span>
                                    2019-06-01 00:00:00
                                </li>
                                <li>
                                    <span class="emp">到期时间</span>
                                    2019-05-31 00:00:00
                                </li>
                                <li>
                                    <span>周期开始时间</span>
                                    2020-06-01 00:00:00
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </transition>

        <!-- 确认订单页面 -->

<!-- <van-loading size="24px">加载中...</van-loading> -->
        <div class="order" v-if="!showGuide">
            <!-- v-else在上面的div首尾添加了过渡标签后无法使用 -->
            <div>
                <div class="info">
                    <div class="info-img" :style="{backgroundImage: 'url(' + require('../../assets/img/' + infoPic) + ')'}"></div>
                    <ul>
                        <li>SIM卡号：{{arrs.msisdn}}</li>
                        <li>ICCID：{{arrs.iccid}}</li>
                        <li>套餐名称：{{arrs.packName}}</li>
                    </ul>
                </div>
                <div class="orderForm">
                    <van-cell-group>
                        <van-cell title="套餐详情" icon="description" value=""/>
                        <van-cell title="流量套餐" :value="arrs.packName"/>
                        <!-- <van-field name="stepper" label="周期">
                            <template #input>
                                <van-stepper v-model="form.minPeriod" :min="1" :max="99" integer/>
                                <van-tag plain type="warning" style="margin-left:8px">周期是当前月/年套餐个数</van-tag>
                            </template>
                        </van-field> -->
                        <!-- <van-field name="stepper" label="期数">
                            <template #input>
                                <van-stepper v-model="form.minPeriod" :min="form.period" :max="99" integer/>
                               
                            </template>
                        </van-field> -->
                        <van-cell title="期数" :value="`${pack.minPeriod}`"/>
                        <van-cell title="单价" :value="`￥${pack.firstPrice}元`"/>
                        <van-cell title="有效期至" :value="date"/>
                        <van-cell title="应付金额" :value="`￥${price}元`"/>
                    </van-cell-group>
                    <div class="orderNote">
                        <van-notice-bar wrapable :scrollable="false">
                        您选择的套餐支付成功后，很快就能生效
                        </van-notice-bar>
                        <van-tag plain>*套餐续费成功后，新套餐将从下一周开始计算，有效时间仅供参考。</van-tag>
                    </div>

                    <van-submit-bar
                        :price="price * 100"
                        button-text="提交订单"
                        @submit="onSubmit">
                    </van-submit-bar>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
    data() {
        return {
            showGuide: true,
            infoPic: 'cmcc.png',
            arrs:{},
            pack:{},
            form:{
                period:1
            },
            chargeOrderId:'',
            timer:Object,
            order:{},
        }
    },
    computed:{
        price(){
            // return (this.form.minPeriod * this.form.period * this.pack.firstPrice).toFixed(2)
            return (this.form.period * this.pack.firstPrice).toFixed(2)
        },
        date(){
            // return this.$dateAdd(this.form.minPeriod * this.form.period, new Date(this.arrs.periodEndTime))
            // return this.$dateAdd(this.form.minPeriod , new Date(this.arrs.periodEndTime))
          const cardEndTime = new Date(this.arrs.cardEndTime);
          const endTime = this.$dateAdd(this.form.period * this.form.periods , new Date(cardEndTime));
          return cardEndTime.getDate() === 26 ? new Date(new Date(endTime).setDate(26)).toLocaleDateString().replaceAll("/", "-") : endTime;
        }
    },
    methods: {
        payFn(d){
                if (!this.$store.state.wxSDKReady) {
                    setTimeout(this.payFn, 1000);
                    return;
                }
               this.checkHasPay(); //点击完成之后，才去校验是否支付，发起业务的消费订单。
                wx.chooseWXPay({
                    timestamp: d.data.timestamp,
                    nonceStr:  d.data.nonceStr,
                    package:   d.data.packAge,
                    signType: "MD5",
                    paySign:   d.data.sign,
                    success: res => { //点击完成之后，才去校验是否支付，发起业务的消费订单。
                    }
                });
        },
        createAccChargeOrder(){
            let params = {amount:this.price,
            // orderForm:1,
            payWay:'weixin-official'};
            this.$api.createAccChargeOrder(params).then(res => {
                if(res.data.code ==0){
                    this.chargeOrderId = res.data.data.orderId
                    this.createAccChargeOrderPay();
                }
            })
        },
        /**
         * 创建账户充值订单
         * @param chargeOrderId
         */
        createAccChargeOrderPay(){
            Toast.loading({duration: 0,mask: true, forbidClick: true });
            let params = {orderId:this.chargeOrderId, payWay:'weixin-official',openId:localStorage.openId};
            this.$api.createOrderPay(params).then(res => {
                Toast.clear();
                let d = res.data;
                if (d.code !== 0) {
                    Toast(d.msg);
                    return;
                }
                this.payFn(d);
            });
        },

        /**
         * 创建账户充值订单
         * @param chargeOrderId
         */
        createAccChargeOrderPay2(){
            Toast.loading({duration: 0,mask: true, forbidClick: true });
            let params = {orderId:this.chargeOrderId, payWay:'weixin-official',openId:localStorage.openId};
            this.$api.createOrderPay2(params).then(res => {
                Toast.clear();
                let d = res.data;
                if (d.code !== 0) {
                    Toast(d.msg);
                    return;
                }
                this.payFn(d);
            });
        },

        checkHasPay(){
            let check = async () =>{
                let params = {chargeOrderId:this.chargeOrderId};
                Toast.loading({duration: 0,mask: true, forbidClick: true });
                let res = await this.$api.queryHasPayed(params);
                //已经支付
                if(!!res.data.data){
                    Toast.clear();
                    // clearInterval(a);
                    if(localStorage.groupClientFlag == '1'){
                        Toast.success('充值成功');
                        this.$router.push({name:'Renew'}); 
                    }else{
                        this.createRenewOrder();
                    }
                } else {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    await check();
                }
            }
            check();
        },
        createRenewOrder(){
            const {msisdn,period} = this.order;
            const params = {
                msisdn,
                period,
                chargeOrderId: this.chargeOrderId, 
                orderFrom: 1,
                openId: localStorage.openId
            };
            this.$api.addRenew(params).then(res => {
                if(res.data.code == 0){
                    if(localStorage.groupClientFlag == '1'){
                        this.chargeOrderId = res.data.data
                        this.createAccChargeOrderPay2()
                    }else{
                        Toast.success('充值成功');
                        this.$router.push({name:'Renew'}); 
                    }
                     
                }
            })
        },
        onSubmit(){
            this.order = {
                msisdn:this.arrs.msisdn,
                iccid:this.arrs.iccid,
                packName:this.arrs.packName,
                cycle:this.form.period,
                period:this.form.period,
                packId:this.pack.packId,
                firstPrice:this.pack.firstPrice,
                date:this.date,
                price:this.price,
                orderFrom:1
            }
            localStorage.renew = JSON.stringify(this.order)
            this.$dialog.confirm({
                title: '续周期',
                message: '您确定续周期吗？续周期只对当前卡片时间延时，流量不增加。'
            }).then(() => {
                // this.$router.push({name:'Renew'});
                this.$api.renewOrderEstablish({list:[this.order],orderFrom:1}).then(res => {
                    if(res.data.code == 0){
                        if(localStorage.groupClientFlag == '1'){
                            this.createRenewOrder()
                        }else{
                            this.createAccChargeOrder();
                        }
                    }
                })
            }).catch(() => {
                // on cancel
            });
        },
        handleOrder() {
            this.$store.commit('setPageTitle', "续周期");
            this.showGuide = false;
        },
    },
    created() {
        this.$store.commit('setPageTitle', "功能说明");
        if(!localStorage.message){
            this.$api.getCardInfo({cardNumber:localStorage.msisdn}).then(res => {
                if(res.data.code == 0){
                    this.arrs = Object.assign({},res.data.data)
                    localStorage.message = JSON.stringify(this.arrs)
                }
            })
        }else{
            this.arrs = JSON.parse(localStorage.message)
        }
        this.$api.getRenewInfo({msisdns:localStorage.msisdn}).then(res => {
            if(res.data.code == 0){
                this.pack = Object.assign({}, res.data.data[0])
                this.form = {
                    minPeriod:1,
                    period:this.pack.minPeriod,
                    periods:this.pack.period
                }
            }
        })
    }
}
</script>

<style lang="scss" scoped>


.info{
    width: 750px;
    height: 255px;
    background-color: #209df2;
    &-img{
        float: left;
        width: 195px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 134px;
    }
    ul{
        float: left;
        margin: 38px 0 0 18px;
        width: 520px;
        li{
            color: #fff;
            font-size: 26px;
            text-align: left;
            height: 61.5px;
            line-height: 61.5px;
        }
    }
}
/* 引导页面的显示/隐藏过渡 */
.guide-hide-enter-active, .guide-hide-leave-active{
    transition: all 0.35s;
}
.guide-hide-enter, .guide-hide-leave-to{
    transform: translateX(-100%);
    opacity: 0;
}

.guide{
    &-title{
        width: 750px;
        height: 190px;
        background-color: #f6f6f6;
        padding: 30px 85px 0;
        box-sizing: border-box;
        text-align: left;
        display: flex;
        &-info{
            flex: 13;
            h1{
                margin: 0;
                font-size: 40px;
                font-weight: normal;
            }
            p{
                margin: 10px 10px 10px 0;
                font-size: 24px;
            }
        }
        &-handle{
            flex: 7;
            button{
                margin-top: 30px;
                border: none;
                border-radius: 10px;
                background-color: #209df2;
                color: #fff;
                font-size: 28px;
                display: block;
                width: 100%;
                height: 75px;
                transition: all 0.15s;
                .iconarrow_right_fat{
                    font-size: 25px;
                }
            }
            button:hover{
                background-color: #1a7fef;
            }
        }
    }
    &-main{
        width: 750px;
        height: 1050px;
        &-contrast{
            margin: 20px auto 40px;
            display: inline-block;
            width: 585px;
            text-align: left;
            h2{
                font-size: 28px;
                font-weight: normal;
                color: #1f9df1;
            }
            &-list{
                border: 1Px solid #ececee;
                border-bottom: none;
                li{
                    height: 55px;
                    line-height: 55px;
                    font-size: 24px;
                    /* text-align: center; 文字居左好看些。如果要居中就写上这行代码并把下面span中的margin-right注释掉 */
                    padding-right: 20px; /* 配合下方span中的margin-right，使文字左右都有20px的空间。如果文字需要居中显示同样请注释掉这行代码 */
                    border-bottom: 1Px solid #ececee;
                    overflow: hidden; /* 内容超出隐藏 */
                    white-space: nowrap; /* 强制文字不换行 */
                    text-overflow: ellipsis; /* 超出显示省略号 */
                    span{
                        float: left;
                        text-align: center;
                        width: 35%;
                        background-color: #888;
                        color: #fff;
                        margin-right: 20px; /* 文字居左显示，拉开间距好看一点 */
                    }
                    .emp{
                        background-color: #1d9fee;
                    }
                }
            }
        }
    }
}
.orderForm{
    text-align: left;
    .van-submit-bar{
        border-top:1px solid #ddd;
    }
}
.orderNote{
    .van-tag {
        margin-left: 20px; 
        margin-top: -10px;
    }
}
</style>